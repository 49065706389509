import React, { useEffect, useState, useRef} from 'react'
import AOS from 'aos';
import { Link } from 'react-router-dom';
import Quote from '../components/Quote/Quote';
import Interpretation from '../components/Interpretation/interpretation';
import Footer from '../components/Footer/Footer';

export default function Home({state}) {
    const QuoteService = () => {setActiveService('document-translation'); setTimeout(()=>{renderServiceRef.current.scrollIntoView({ behavior: 'smooth' })},100)}
    useEffect(()=>{
      if(state){QuoteService()}
    },[state])
    ////////////////
    const [services, setServices] = useState([
        {
            id: 1,
            title: "Legal & Immigration",
            description: "The Precision of Legal Language At Falcon Translation, we ensure every legal term and clause is translated with unparalleled precision. Our translators, experts in British law, treat each document as a vital entity, preserving its original power and intent.Immigration – A Pathway to New BeginningsWe understand the significance of immigration documents and translate them with the care they deserve, ensuring they resonate with the aspirational spirit of our clients.Beyond Translation – Legal Transformation Our translations transform legal contracts into enforceable agreements across borders, maintaining the intent and authority of the original documents.Conclusion Falcon Translation is your trusted partner in navigating the globalized legal landscape, providing translations that carry the authority and precision of British legal expertise.",
            link: "Legal-Immigration",
            icon: "fa-solid fa-scale-balanced icon",
            mini:"The Precision of Legal Language At Falcon Translation, we ensure every legal term and clause is translated with unparalleled precision.",
        },
        {
            id: 2,
            title: "NHS & Healthcare",
            description: "We play a critical role in the NHS by delivering translations that combine medical accuracy with empathetic communication.Precision and Empathy: The Falcon Touch Our team ensures that medical documents, from PILs to clinical reports, are translated with the highest care, making medical information accessible to all.A Bridge Between Cultures We eliminate language barriers in healthcare, providing translations that reflect the UK’s diverse population.Case Studies: Examples of Excellence Our projects, from PILs to clinical studies, showcase our commitment to excellence and cultural sensitivity in translations.The Falcon Promise We pledge to uphold the highest standards of quality and confidentiality, ensuring our translations meet all regulatory standards",
            link: "NHS-Healthcare",
            icon: "fa-solid fa-staff-snake icon",
            mini:"We play a critical role in the NHS by delivering translations that combine medical accuracy with empathetic communication.",
        },
        {
            id: 3,
            title: "Education",
            description: "rvices Falcon Translation propels educational ventures forward with expert academic translations. We ensure every research paper, study, and lecture transcends language barriers, connecting British academia to a worldwide audience. Our linguists excel in translating complex academic works like theses and dissertations, adapting course materials for global programs, and preparing conference materials that foster international scholarly exchange.Adhering to British Law and Translation Rules Our translations reflect the precision and integrity required by British law, with every document meeting stringent legal and academic standards.Competitive Edge in the Market Falcon Translation distinguishes itself with a commitment to transforming academic content into globally engaging material, providing our clients with a competitive advantage.Conclusion : We make education’s passport to the future linguistically valid, culturally sensitive, and globally recognized, expanding knowledge and opportunities without borders.",
            link: "Education",
            icon:"fa-solid fa-book-open-reader icon",
            mini:"Services Falcon Translation propels educational ventures forward with expert academic translations. We ensure every research paper.",
        },
        {
            id: 4,
            title: "Proofreading and Editorial Services",
            description: "Our Proofreading and Editorial Services enhance your translated documents’ precision and clarity. Our proofreaders, experts in British English, ensure grammatical excellence, while our editors craft narratives with compelling style and persuasive tone.Final Arbiters of Readiness Our editorial team perfects your documents, ensuring they’re ready to captivate and inform a global audience.Polished Perfection Awaits We tailor our services to your needs, ensuring compliance with British translation and proofreading standards, and elevating your communication to unmatched excellence",
            link: "Proofreading-Editorial-Services",
            icon:"fa-solid fa-envelope-circle-check icon",
            mini:"Our Proofreading and Editorial Services enhance your translated documents’ precision and clarity. Our proofreaders, experts in British English.",
        },
        {
            id: 5,
            title: "official Identifications",
            description: "Identity Translation Services Falcon Translation specializes in translating key identity documents with precision and professionalism. We ensure passports, driver’s licenses, and birth certificates meet international standards and are meticulously crafted for global recognition and legal compliance.Our Commitment to Quality and Compliance We guarantee accuracy, adherence to British law, and confidentiality, setting us apart with our expertise, cutting-edge technology, and exceptional customer service.Competitive Edge Our translators’ legal expertise and personalized service provide a competitive advantage, ensuring your identity documents are a bridge to your future.",
            link: "official-Identifications",
            icon:"fa-solid fa-fingerprint icon",
            mini:"Identity Translation Services Falcon Translation specializes in translating key identity documents with precision and professionalism. We ensure passports, driver's licenses.",
        },
        {
            id: 6,
            title: "Finance",
            description: "Financial Translation Services Falcon Translation is your partner in the global finance sector, offering precise and accurate financial document translations.Why Choose Falcon Translation? We provide expert translations with comprehensive expertise, regulatory compliance, cultural insight, and a competitive edge.Our Services Include: We translate annual reports, earnings statements, market analyses, and investor communications, tailoring solutions to each client’s needs.Ensuring Your Numbers Narrate Your Story Our commitment to excellence turns your financial data into compelling narratives that resonate globally.",
            link: "Finance",
            icon:"fa-solid fa-chart-line icon",
            mini:"Financial Translation Services Falcon Translation is your partner in the global finance sector, offering precise and accurate financial document translations.",
        },
        {
            id: 7,
            title: "Professional Specializations",
            description: "Specialized Falcon Translation offers specialized services across various sectors, ensuring precision and cultural relevance in every project.Medical Translations: We deliver error-free translations in medical documents, adhering to UK’s MHRA standards, making complex information accessible to all.Scientific Translations: Our expertise in scientific terminology enables us to translate research papers and articles, preserving the original content’s integrity for a global audience.Interior Design Translations: We translate design concepts with an understanding of cultural nuances, ensuring the designer’s vision is communicated with elegance.Media Translations: We provide translations for the media sector, considering local cultural sensitivities and compliance with Ofcom broadcasting codes for the UK audience.Our Pledge: At Falcon Translation, we’re committed to delivering translations that resonate with technical accuracy and creative expression, tailored to your audience’s cultural context. Trust us to elevate your global communications.",
            link: "Professional-Specializations",
            icon:"fa-solid fa-globe icon",
            mini:"Specialized Falcon Translation offers specialized services across various sectors, ensuring precision and cultural relevance in every project.",
        },
    ]);
    /////////////////

    const renderServiceRef = useRef(null); 
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const handleResizee = () => {
        setIsMobile(window.innerWidth <= 768);
      };
    const [backgroundImage, setBackgroundImage] = useState('/assets/images/BGPhotoFinal.jpg');
    
    useEffect(() => {
        AOS.init();
        window.scrollTo(0,0);
        window.addEventListener('resize', handleResizee);
        ///
        const updateBackgroundImage = () => {
            if (window.innerWidth <= 768) {
              setBackgroundImage('/assets/images/MobileBG.jpg');
            } else {
              setBackgroundImage('/assets/images/BGPhotoFinal.jpg');
            }
          };
          updateBackgroundImage(); // Initial check
          window.addEventListener('resize', updateBackgroundImage);
        ////
          const handleResize = () => {
            setGap(window.innerWidth <= 768 ? '1px' : '200px');
            setButtonStyle({
                width: window.innerWidth <= 768 ? '150px' : '300px',
                height: window.innerWidth <= 768 ? '60px' : '65px',
                fontSize: window.innerWidth <= 768 ? '12px' : '16px',
                padding: window.innerWidth <= 768 ? '15px' : '1px',
                imgwidth: window.innerWidth <= 768 ? '180px ':'300px' ,
                imgheight: window.innerWidth <= 768 ? '170px ':'250px' ,
                fontSizefalcon: window.innerWidth <= 768 ? '1.4rem':'2.5rem' ,
                fontSizetranslation: window.innerWidth <= 768 ? '0.8rem':'1.4rem' ,
                font: window.innerWidth <= 768 ? '0.8rem':'',
                marginBottom: window.innerWidth <= 768 ? '20px' : '60px',
                marginTop: window.innerWidth <= 768 ? '10px' : '',  
                hrwidth: window.innerWidth <= 768 ? '64%' : '35%', 
            });
          };

          window.addEventListener('resize', handleResize);
          return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('resize', handleResizee);
            window.removeEventListener('resize', updateBackgroundImage);
          }
    }, []);

    const [activeService, setActiveService] = useState(null);
    const [gap, setGap] = useState(window.innerWidth <= 768 ? '1px' : '200px');
    const [buttonStyle, setButtonStyle] = useState({
        width: window.innerWidth <= 768 ? '150px' : '300px',
        height: window.innerWidth <= 768 ? '60px' : '65px',
        fontSize: window.innerWidth <= 768 ? '12px' : '16px',
        padding: window.innerWidth <= 768 ? '15px' : '1px',
        imgwidth: window.innerWidth <= 768 ? '180px ':'300px' ,
        imgheight: window.innerWidth <= 768 ? '170px ':'250px' ,
        fontSizefalcon: window.innerWidth <= 768 ? '2.0rem':'2.5rem' ,
        fontSizetranslation: window.innerWidth <= 768 ? '1.1rem':'1.4rem' ,
        font: window.innerWidth <= 768 ? '0.8rem':'', 
        marginBottom: window.innerWidth <= 768 ? '20px' : '30px',
        marginTop: window.innerWidth <= 768 ? '10px' : '',  
        hrwidth: window.innerWidth <= 768 ? '64%' : '35%', 
    });
    
  const renderService = () => {
    // eslint-disable-next-line default-case
    switch (activeService) {
      case 'document-translation':
        return <Quote />;
      case 'interpretation':
        return <Interpretation />;
    }
  };

  const [heroHeight, setHeroHeight] = useState('');

  useEffect(() => {
    const updateBackgroundSize = () => {
      const height = window.innerHeight;
      const navbar = document.querySelector('.navbar');
        if (navbar) {
            const navbarHeight = navbar.clientHeight;
            setHeroHeight(`${height-navbarHeight}px`);
        }
    };
    updateBackgroundSize();
    window.addEventListener('resize', updateBackgroundSize);

    return () => {
      window.removeEventListener('resize', updateBackgroundSize);
    };
  }, []);

  return (
    <>
        <div className="background" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: '100% 100vh', backgroundPosition: 'center', height: heroHeight, paddingTop:'10px'}}>
            <div className='container h-100 d-flex align-items-center justify-content-center text-center'>
                <div>
                    <div style={{marginBottom:buttonStyle.marginBottom}}> 
                <img src="/assets/images/falconLogo.png" alt="logo" className="d-inline-block align-text-top" style={{ width:buttonStyle.imgwidth ,height:buttonStyle.imgheight }}/>
                    {/* <h2 className='text-black' style={{fontSize: buttonStyle.fontSizefalcon, fontWeight:'bold' }}>Falcon</h2>
                    <h3 className='text-black' style={{fontSize:buttonStyle.fontSizetranslation}}>Translation</h3> */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                             <div style={{width: buttonStyle.hrwidth ,height:'3px',backgroundColor:'black',marginBottom:'10px'}} />
                       </div>

                    <h6 className='text-black' style={{fontSize:buttonStyle.font}}>Accuracy. Speed. Efficiency</h6>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                             <div style={{width: buttonStyle.hrwidth, height:'3px',backgroundColor:'black' }} />
                       </div>
                    </div>
                    <div className={isMobile ? 'bottom':''}></div>
                    <div>
                    <p className={isMobile ? 'text-black fs-5 gothic' : 'text-black fs-3 gothic'}>We provide high-quality translation services to clients in the UK <br></br> and around the world.</p>
                    
                    {/* <Link to="/contact" className='btn btn-violet py-2 px-3 fs-5 gothic'>Get Your Quote Now!</Link> */}
                    
                    <div style={{display: 'flex', gap: gap,marginTop:'10px',justifyContent:'center'}}>
                    
                    <div >
                    
    <div className='services-card' style={{width:buttonStyle.width,height:buttonStyle.height,cursor: 'pointer',boxShadow:"0 0 30px 10px rgba(0, 0, 0, 0.4)",background:`linear-gradient(${activeService === 'document-translation' ? '#4a259b' : 'rgba(255, 250, 250, 0.5)'}, ${activeService === 'document-translation' ? '#4a259b' : 'rgba(255, 250, 250, 0.5)'})`,borderLeft: activeService === 'document-translation' ? '10px solid #e5e0f1' :'10px solid #4a259b' }} onClick={QuoteService}>
  
        <div className={isMobile ? 'text-bottom' : 'text-center'}>
        <h6 style={{color: activeService === 'document-translation' ? 'white' : '#4a259b',fontSize:buttonStyle.fontSize}}>Fast Quote</h6>
     <h6 style={{marginTop:"4px",color:activeService === 'document-translation' ? 'white' : 'black', fontSize:buttonStyle.fontSize}}>Document Translation</h6>
        </div>
    </div>
</div>
{/* <div>
    <div className='services-card' style={{paddingTop:buttonStyle.padding,width:buttonStyle.width,height:buttonStyle.height,cursor: 'pointer',boxShadow:"0 0 30px 10px rgba(0, 0, 0, 0.4)",background:`linear-gradient(${activeService === 'interpretation' ? '#4a259b' : 'rgba(255, 250, 250, 0.5)'}, ${activeService === 'interpretation' ? '#4a259b' : 'rgba(255, 250, 250, 0.5)'})`,borderLeft: activeService === 'interpretation' ? '10px solid #e5e0f1' :'10px solid #4a259b' }} onClick={() => {setActiveService('interpretation'); renderServiceRef.current.scrollIntoView({ behavior: 'smooth' });}}>
        <div className={isMobile ? 'text-leftt' : 'text-center'}>
        <h6 style={{color: activeService === 'interpretation' ? 'white' : '#4a259b',fontSize:buttonStyle.fontSize}}>Fast Quote</h6>
            <h6 style={{marginTop:"4px",color:activeService === 'interpretation' ? 'white' : 'black', fontSize:buttonStyle.fontSize}}>Interpretation</h6>
        </div>
    </div>
</div>
                         */}
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div ref={renderServiceRef}>
        {renderService()}
        </div>
        {/* <Quote /> */}
        <br></br>

        
        <div className='py-5'>
        <h2 className={isMobile?'text-center fontt':'text-center fontt bottomm-falcon'}>We Are Falcons</h2>
            <div className='container col-10'>
                <div className='row'>
                    <div className='col-md-6'>
                        <p className='text-size gothic' style={{display:'flex',alignItems:'start'}}>
                            <span className='box' style={{marginRight:'8px',marginTop:'6px'}}></span> 
                        <span className='text' style={{flex:'1'}}>
                        In the realm of global discourse, Falcon Translation Company stands as a beacon of linguistic mastery, reflecting the noble traits of the falcon—Accuracy. Speed. Efficiency.
                         With the precision of a falcon's strike, we deliver translations that resonate with clarity and intent. Our rapid service is the embodiment of the falcon’s swift hunt, ensuring messages are conveyed with prompt elegance. The efficiency of our approach mirrors the falcon’s seamless soar, offering solutions that embody the fluid grace of this regal bird.
                         
                        </span>
                         </p>
                    </div>
                    <div className='col-md-6'>
                        <img src='/assets/images/about-us.webp' alt='About Us' className='img-fluid rounded' style={{borderLeft: '10px solid #4a259b'}} />
                    </div>
                </div>
            </div>
        </div>

        <div className='py-5 bg-gray'>
            <h1 className='text-center fontt'>Our Services</h1>
            <div className='container'>
            <div className="row isotopeContainer">
                  {services && services.map((service,index) => {
                    return (
                      //
                    <div key={service.id} className={`col-md-4 mb-3 d-flex portfolio_grid no-padding isotopeSelector ${index === services.length - 1 ? 'centered' : ''}`} style={index === services.length - 1 ? { margin: 'auto', display: 'block' } : {}}>
                     <figure className="portfolio_item">
                        <div className='services-card py-5'>
                            <div className='text-center'>
                            <i className={service.icon}></i>
                                <h6 className='mt-3'>{service.title}</h6>
                                <p className='gothic' style={{display:'flex',alignItems:'start'}}>
                                    <span className='small-box' style={{marginRight:'8px',marginTop:'6px'}}> </span> 
                                    <span className='text' style={{flex:'1'}}>
                                    {service.mini}
                                    </span>
                                 
                                </p>
                                <Link to={`/service/${service.link}`} state={{ service }} className='btn btn-violet gothic py-2 px-3 fs-6'>Read More</Link>
                            </div>
                        </div>
                    
                </figure>
                    </div>)
                  })}
                  </div> 
            </div>
        </div>
        <div className='col-md-4 mb-3'> </div>   
        <div className='py-5 bg-gray'>
  <h1 className='text-center fontt'>Our Qualifications</h1>
  <div className='container'>
    <div className="row isotopeContainer">
      <div className='col-md-4 mb-3 d-flex portfolio_grid no-padding isotopeSelector centered' style={{ margin: 'auto', display: 'block' }}>
        <figure className="portfolio_item">
          <div className='services-card py-5'>
            <div className='text-center'>
              <i className="fa-solid fa-ribbon icon"></i>
              {/* <h1 className='text-center fontt'>Our Qualifications</h1> */}
              <div className='container' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src="/assets/images/certificate.jpg" alt="logo" width="100%" className="d-inline-block align-text-top" />
                <p className='text-size gothic' style={{ textAlign: 'center' }}>
                  <span className='box'></span> We are accredited by Chartered Institute of Linguistics CIOL.
                </p>
              </div>
            </div>
          </div>
        </figure>
      </div>
      <Link to="/reviews" aria-current="page" style={{display:'flex', justifyContent:'center',color:'#4a259b',fontFamily:'good'}}>Our Reviews</Link>
    </div>
  </div>
</div>


  <div className='col-md-4 mb-3'> </div> 
  
        <Footer service={QuoteService}/>
    </>
  )
}