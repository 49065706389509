import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import 'aos/dist/aos.css';
import Service from './pages/service';
import Services from './pages/Services';
import Story from './pages/ourStory';
import Contact from './pages/Contact';
import Review from './pages/Review';
import Reviews from './pages/Reviews';
import React, { useState } from 'react';

function App() {
  // console.log(QuoteService);
  const [state, setState] = useState(0)

  
  const open = ()=>{
    console.log("called");
    setState(0);
    setState(1);
  }


  return (
    <Router>
      <Navbar QuoteService={open}/>
      <Routes>
        <Route path="/" element={<Home state={state} />} />
        <Route path="/service" element={<Service test={open} />} />
        <Route path="/ourStory" element={<Story test={open}/>} />
        <Route path="/service/:name" element={<Services test={open} />} />
        <Route path="/contact" element={<Contact test={open}/>} />
        <Route path="/review/:id" element={<Review test={open}/>} />
        <Route path="/reviews" element={<Reviews test={open}/>} />
      </Routes>
      {/* <Footer/> */}
      </Router>
  );
}

export default App;
