import React, { useEffect } from 'react'
import ServiceComponent from '../components/Services/ServiceComponent'


export default function ServiceOnly({test}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ServiceComponent test={test} />
    </>
  )
}
