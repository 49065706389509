import React, { useRef, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2';

export default function Interpretation() {
  const [selectedOption, setSelectedOption] = useState('');
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  
  };
  /////
  const [times, setTimes] = useState({ from: '', to: '' });
  const [timeDifference, setTimeDifference] = useState('');
  const [orgTimes, setOrgTimes] = useState({ org_from: '', org_to: '' });
  const [orgTimeDifference, setOrgTimeDifference] = useState('');
  const handleTimeChange = (event) => {
    const { name, value } = event.target;
    setTimes((prevTimes) => ({
      ...prevTimes,
      [name]: value,
    }));
  };
  const handleOrgTimeChange = (event) => {
    const { name, value } = event.target;
    setOrgTimes((prevTimes) => ({
      ...prevTimes,
      [name]: value,
    }));
  };

  const calculateTimeDifference = () => {
    const { from, to } = times;
    if (from && to) {
      const [fromHours, fromMinutes] = from.split(':').map(Number);
      const [toHours, toMinutes] = to.split(':').map(Number);

      const fromTime = new Date(0, 0, 0, fromHours, fromMinutes);
      const toTime = new Date(0, 0, 0, toHours, toMinutes);

      const difference = toTime - fromTime;
      const diffHours = Math.floor(difference / (1000 * 60 * 60));
      const diffMinutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

      setTimeDifference(`${diffHours} hour(s) and ${diffMinutes} minute(s)`);
    } else {
      setTimeDifference('');
    }
  };
  const calculateOrgTimeDifference = () => {
    const { org_from, org_to } = orgTimes;
    if (org_from && org_to) {
      const [fromHours, fromMinutes] = org_from.split(':').map(Number);
      const [toHours, toMinutes] = org_to.split(':').map(Number);

      const fromTime = new Date(0, 0, 0, fromHours, fromMinutes);
      const toTime = new Date(0, 0, 0, toHours, toMinutes);

      const difference = toTime - fromTime;
      const diffHours = Math.floor(difference / (1000 * 60 * 60));
      const diffMinutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

      setOrgTimeDifference(`${diffHours} hour(s) and ${diffMinutes} minute(s)`);
    } else {
      setOrgTimeDifference('');
    }
  };

  React.useEffect(() => {
    calculateTimeDifference();
    calculateOrgTimeDifference();
  }, [times,orgTimes]);
  /////////
  
  ///////////

  const [errors, setErrors] = useState([]);
  const errorsRef = useRef(null);
  const formRef = useRef(null);
  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    field_inter:'',
    numof_inter:'',
    name_service:'',
    inter_lang:'',
    dialect:'',
    prefeered_dialect:'',
    org_date:'',
    org_from:'',
    org_to:'',
  };
  
  const [formData, setFormData] = useState(initialFormData);

  const [isLoading, setIsLoading] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = new FormData();
    form.append('name', formData.name);
    form.append('email', formData.email);
    form.append('phone', formData.phone);
    form.append('field_inter', formData.field_inter);
    form.append('numof_inter', formData.numof_inter);
    form.append('name_service', formData.name_service);
    form.append('inter_lang', formData.inter_lang);
    form.append('dialect', formData.dialect);
    form.append('prefeered_dialect', formData.prefeered_dialect);
    form.append('org_date', formData.org_date);
    form.append('org_from', formData.org_from);
    form.append('org_to', formData.org_to);
    
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
    };
  
    try {
      //await axios.post('http://localhost:3001/api/projects/user', form, config);
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Your quote has been sent we will contact you soon!',
      });

      setErrors([]);
      setFormData(initialFormData);
      formRef.current.reset();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
        errorsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } finally {
      setIsLoading(false);
      window.location.href = '/';
    }
  };

  const handleInputChange = async (e) => {
    const { name, value, files } = e.target;
    let input = value;
    if (files) {
      input = Array.from(files);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: input,
    }));
  }; 
  const handleCancelClick = () => {
    window.location.href = '/';
  };
  return (
    <div>
    {/* <div className="container py-5 d-flex justify-content-center">
      <select value={selectedOption} onChange={handleChange} className="form-select mb-4 custom-select">
        <option value="">Service for?</option>
        <option value="individual">Individual</option>
        <option value="organization">Organization</option>
      
      </select>
    </div> */}

    {/* {selectedOption === 'individual' && (
      <div>
        <div className="container py-5" ref={errorsRef}>
          <h1 className="fontt" style={{ textAlign: 'center' }}>interpretation for Individual</h1>
          <p className="text-size gothic" style={{ display: 'flex', alignItems: 'start' }}>
            <span className="box" style={{ marginRight: '8px', marginTop: '6px' }}></span>
            <span className="text" style={{ flex: '1' }}>
              Let Falcon Service Translations be your trusted partner for all your translation needs. Contact us today for a free consultation and a quote tailored to your specific requirements. We look forward to helping you and aim to reply to all emails within 15 – 30 minutes during working hours, when possible.
            </span>
          </p>
        </div>
        <form onSubmit={handleSubmit} ref={formRef}>
          <div className="container">
            {errors.length > 0 && (
              <div className="alert alert-danger" role="alert">
                <ul className="mb-0">
                  {errors.map((error, index) => (
                    <li className="gothic" key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label className="gothic" htmlFor="name">Clint Name</label>
                  <input type="text" className="form-control" name="name" value={formData.name} onChange={handleInputChange} />
                </div>
                <div className="form-group mb-3">
                  <label className="gothic" htmlFor="email">Email address</label>
                  <input type="email" className="form-control" name="email" value={formData.email} onChange={handleInputChange} />
                </div>
                <div className="form-group mb-3">
                  <label className="gothic" htmlFor="phone">Phone number</label>
                  <input type="text" className="form-control" name="phone" value={formData.phone} onChange={handleInputChange} />
                </div>
                <div className="form-group mb-3">
                  <label className="gothic" htmlFor="source_language">Field of Interpretation</label>
                  <input type="text" className="form-control" name="source_language" value={formData.source_language} onChange={handleInputChange} />
                </div>
                <div className="form-group mb-3">
                  <label className="gothic" htmlFor="target_language">The name of the service</label>
                  <input type="text" className="form-control" name="target_language" value={formData.target_language} onChange={handleInputChange} />
                </div>
              </div>
              <div className="form-group mb-3">
                <label className="gothic" htmlFor="message">Interpreter Language</label>
                <input className="form-control" name="notes" onChange={handleInputChange}></input>
              </div>
              <div className="form-group mb-3">
                  <label className="gothic" htmlFor="dialect">Dialect</label>
                  <input type="text" className="form-control" name="dialect"  onChange={handleInputChange} />
                </div>
                <div className="form-group mb-3">
                  <label className="gothic" htmlFor="preferred_dialect">preferred Dialect</label>
                  <input type="text" className="form-control" name="preferred_dialect"  onChange={handleInputChange} />
                </div>
                <div className="form-group mb-3">
                  <label className="gothic" htmlFor="date">Service Date</label>
                  <input type="date" className="form-control" name="date"  onChange={handleInputChange} />
                </div>
                <div className="form-group mb-3">
                  <label className="gothic">Service Time</label>
                </div>
              
                <div className="col-md-6 form-group mb-3">
                  <label className="gothic" htmlFor="from">Start time</label>
                  <input type="time" min="00:00" max="24:00" className="form-control" name="from"  onChange={handleTimeChange} />
                </div>
                <div className="col-md-6 form-group mb-3">
                  <label className="gothic" htmlFor="to">End time</label>
                  <input type="time" className="form-control" name="to"  onChange={handleTimeChange} />
                </div>
                <div className="form-group mb-3">
                {timeDifference && (
        <div className="mt-3">
          <p className="gothic">Time Difference: {timeDifference}</p>
        </div>
      )}
                </div>
               
            </div>
          </div>
          <div className="container text-center py-5">
            <button type="submit" className="btn btn-violet fs-6 py-2 px-3 text-center gothic">
              {isLoading ? 'Loading...' : 'Submit Quote'}
              {isLoading && <div className="spinner-border" style={{ width: '15px', height: '15px' }} role="status">
                <span className="sr-only">Loading... </span>
              </div>}
            </button>
          </div>
        </form>
      </div>
    )} */}
  
      <div>
        <div className="container py-5" ref={errorsRef}>
          <h1 className="fontt" style={{ textAlign: 'center' }}>Fast interpretation quote</h1>
          <p className="text-size gothic" style={{ display: 'flex', alignItems: 'start' }}>
            <span className="box" style={{ marginRight: '8px', marginTop: '6px' }}></span>
            <span className="text" style={{ flex: '1' }}>
              Let Falcon Service Translations be your trusted partner for all your translation needs. Contact us today for a free consultation and a quote tailored to your specific requirements. We look forward to helping you and aim to reply to all emails within 15 – 30 minutes during working hours, when possible.
            </span>
          </p>
        </div>
        <form onSubmit={handleSubmit} ref={formRef}>
          <div className="container">
            {errors.length > 0 && (
              <div className="alert alert-danger" role="alert">
                <ul className="mb-0">
                  {errors.map((error, index) => (
                    <li className="gothic" key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label className="gothic" htmlFor="name">Client name</label>
                  <input type="text" className="form-control" name="name" value={formData.name} onChange={handleInputChange} />
                </div>
                <div className="form-group mb-3">
                  <label className="gothic" htmlFor="email">Email address</label>
                  <input type="email" className="form-control" name="email" value={formData.email} onChange={handleInputChange} />
                </div>
                <div className="form-group mb-3">
                  <label className="gothic" htmlFor="phone">Phone number</label>
                  <input type="text" className="form-control" name="phone" value={formData.phone} onChange={handleInputChange} />
                </div>
                <div className="form-group mb-3">
                  <label className="gothic" htmlFor="field_inter">Field of interpretation</label>
                  <input type="text" className="form-control" name="field_inter" value={formData.source_language} onChange={handleInputChange} />
                </div>
                <div className="form-group mb-3">
                  <label className="gothic" htmlFor="numof_inter">The number of interpreters</label>
                  <input type="text" className="form-control" name="numof_inter" value={formData.target_language} onChange={handleInputChange} />
                </div>
              </div>
              <div className="form-group mb-3">
                <label className="gothic" htmlFor="name_service">The name of service</label>
                <input className="form-control" name="name_service" onChange={handleInputChange}></input>
              </div>
              <div className="form-group mb-3">
                <label className="gothic" htmlFor="inter_lang">Interpreter language</label>
                <input className="form-control" name="inter_lang" onChange={handleInputChange}></input>
              </div>
              <div className="form-group mb-3">
                <label className="gothic" htmlFor="dialect">Dialect</label>
                <input className="form-control" name="dialect" onChange={handleInputChange}></input>
              </div>
              <div className="form-group mb-3">
                <label className="gothic" htmlFor="prefeered_dialect">Preferred dialect</label>
                <input className="form-control" name="prefeered_dialect" onChange={handleInputChange}></input>
              </div>
              <div className="form-group mb-3">
                  <label className="gothic" htmlFor="org_date">Service Date</label>
                  <input type="date" className="form-control" name="org_date"  onChange={handleInputChange} />
                </div>
                <div className="form-group mb-3">
                  <label className="gothic" >Service Time</label>
                </div>
              
                <div className="col-md-6 form-group mb-3">
                  <label className="gothic" htmlFor="org_from">Start time</label>
                  <input type="time" className="form-control" name="org_from"  onChange={handleOrgTimeChange} />
                </div>
                <div className="col-md-6 form-group mb-3">
                  <label className="gothic" htmlFor="org_to">End time</label>
                  <input type="time" className="form-control" name="org_to"  onChange={handleOrgTimeChange} />
                </div>
                <div className="form-group mb-3">
                {orgTimeDifference && (
        <div className="mt-3">
          <p className="gothic">Time Difference: {orgTimeDifference}</p>
        </div>
      )}
                </div>
            </div>
          </div>
          <div className="container text-center py-5" style={{display:'flex', justifyContent:'center', gap: '10px' }}>
            <button type="submit" className="btn btn-violet fs-6 py-2 px-3 text-center gothic">
              {isLoading ? 'Loading...' : 'Submit Quote'}
              {isLoading && <div className="spinner-border" style={{ width: '15px', height: '15px' }} role="status">
                <span className="sr-only">Loading... </span>
              </div>}
            </button>
            <button type='button' className='btn btn-violet fs-6 py-2 px-3 text-center gothic' onClick={handleCancelClick}>
        Cancel
      </button>
          </div>
        </form>
      </div>
    
  </div>
  )
}