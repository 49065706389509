import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation , useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';

export default function Navbar({QuoteService}) {
  const [showModal, setShowModal] = useState(false);//!
  const location = useLocation();
  const navigate = useNavigate()

  const open = ()=>{
    if(window.location.pathname === "/"){
      QuoteService()  
      console.log("/");    
    }else{
      navigate("/")
      QuoteService()
    }
  }
const [menuOpen, setMenuOpen] = useState(false);
const menuButtonRef = useRef(null);

const [formData, setFormData] = useState({
  client_name: '',
  client_email: '',
  client_phone: '',
  notes: ''
});//!
const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
};//!
const handleSubmit = async (e) => {
  e.preventDefault();
  console.log("submited");
  try {
    const response = await fetch('https://falcon-backend.hemmx.org/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
    console.log(response);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const result = await response.json();
    console.log(result);
    // Close modal and reset form
    Swal.fire({
      icon: 'success',
      title: 'Success!',
      text: 'Your message has been sent ,' + getResponseMessage(),
      
    }).then(val=>{
      setShowModal(false);
      setFormData({
        client_name: '',
        client_email: '',
        client_phone: '',
        notes: ''
    })
    });
  } catch (error) {
    console.log('Error:', error);
  }
};//!
const toggleMenu = () => {
  setMenuOpen(!menuOpen);
};

const closeMenu = (event) => {
  if (menuButtonRef.current && !menuButtonRef.current.contains(event.target)) {
    setMenuOpen(false);
  }
};

useEffect(() => {
  document.addEventListener('click', closeMenu);
  return () => {
    document.removeEventListener('click', closeMenu);
  };
}, []);
const handleOpenModal = () => {
  setShowModal(true);
};//!

const handleCloseModal = () => {
  setShowModal(false);
};//!

const handleContactUsClick = () => {
  // Define the logic for handling contact us click
  console.log('Contact Us clicked');
  // Example: Triggering modal open
  handleOpenModal();
};//!

////////
const getResponseMessage = () => {
  const now = new Date();
  const day = now.getDay();
  const hour = now.getHours();

  if (day >= 1 && day <= 5) { // Monday to Friday
    if (hour >= 9 && hour < 17) {
      return 'We will respond to you by email in 15 minutes.';
    } else {
      return 'We will respond to you by email in a while.';
    }
  } else { // Saturday and Sunday
    return 'We will respond to you by email in a while.';
  }
};//!
////////

const handleServicesClick = (event) => {
  event.stopPropagation();
  setMenuOpen(true);
};

const handleHomeClick = (event) => {
  if (location.pathname === '/') {
    event.preventDefault();
    window.location.reload();
  }
};

  return (
    <>
        <nav className="navbar navbar-expand-lg" style={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor:'rgb(243, 243, 243)' }}>
            <div className="container">
                <Link to="/" className="navbar-brand" aria-current="page" style={{textAlign:"center"}}>
                    <img src="/assets/images/falconLogo.png" alt="logo" width="90" height="80" className="d-inline-block align-text-top"/>
                    {/* <div>
                       <h2 className='text-black' style={{ fontSize: '0.9rem', fontWeight: 'bold', margin: 0 }}>Falcon</h2>
                       <h3 className='text-black' style={{ fontSize: '0.5rem', margin: 0 }}>Translation</h3>
                    </div> */}
                </Link>
                <button className="navbar-toggler" ref={menuButtonRef} onClick={toggleMenu} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`}  id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <Link to="/" className={`nav-link font-good ${location.pathname === '/' ? 'active' : ''}`} aria-current="page" onClick={handleHomeClick}>Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/ourStory" className={`nav-link font-good ${location.pathname === '/ourStory' ? 'active' : ''}`} aria-current="page">Our Story</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/service" className={`nav-link font-good ${location.pathname === '/service' ? 'active' : ''}`} aria-current="page">Our Services</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="#" className="nav-link font-good" onClick={handleContactUsClick} aria-current="page">Contact Us</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/" className="nav-link font-good" onClick={open} aria-current="page">Quotation</Link>
                    </li>
                </ul>
                </div>
            </div>
        </nav>
        {showModal && (
        <div className="modal modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-dialog">
    <div class="modal-content">
      {/* <div className='container col-12' style={{display:'flex', justifyContent:'start'}}>
        <div className='row'>
        <button style={{backgroundColor:'#D3D3D3',borderRadius:'15px',width:'120px',height:'35px',padding:'2px',margin:'2px'}}>
      <h6>
      <button type="button" class="btn-close" data-bs-dismiss="modal" onClick={handleCloseModal}></button>
      close
      </h6>
      </button>
        </div>
      </div> */}
      <div class="modal-header"style={{backgroundColor:'white'}}>
        <div className='container services-card py-5 pt-1 mt-4 pb-3 align-items-center justify-content-center text-center' style={{width:"280px"}}>
        {/* <h4 style={{color:'#4a259b', fontSize:'16px',textAlign:'center',paddingTop:'8px'}}>Contact Us</h4> */}
        <i class="fa-solid fa-comments icon" style={{fontSize:"3rem"}}></i>
        <h4 style={{color:'#4a259b', fontSize:'17px',textAlign:'center', paddingTop:'5px'}}>How can we help?</h4>
        <p style={{fontSize:'0.8rem',textAlign:'center'}}> {getResponseMessage()}</p>
        </div>
      </div>
      <div class="modal-body">
      <form onSubmit={handleSubmit}>
          <div class="form-group">
            <label>Name:</label>
            <input type="text" class="form-control" name="client_name" value={formData.client_name} onChange={handleChange} required></input>
          </div>
          <div class="form-group">
            <label>Phone number:</label>
            <input type="text" class="form-control" name="client_phone" value={formData.client_phone} onChange={handleChange} required></input>
          </div>
          <div class="form-group">
            <label>Email address:</label>
            <input type="email" class="form-control" name="client_email" value={formData.client_email} onChange={handleChange} required></input>
          </div>
          <div class="form-group">
            <label>Write your message here:</label>
            <textarea type="text" class="form-control" style={{height:'100px'}} name="notes" value={formData.notes} onChange={handleChange} required></textarea>
          </div>
          <div class="modal-footer" style={{display:'flex',justifyContent:'start'}}>
        <button type="button" class="btn" data-bs-dismiss="modal" onClick={handleCloseModal} style={{backgroundColor:'#4a259b',color:'white'}}>Close</button>
        <button type="submit" class="btn" data-bs-dismiss="modal" style={{backgroundColor:'#4a259b',color:'white'}}>Send a message</button>
      </div>
        </form>
      </div>

      

    </div>
  </div>
        </div>
      )}

    </>
  )
}
