import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Quote from '../Quote/Quote'
import Footer from '../Footer/Footer';

export default function Service({services,test2}) {
    console.log(test2);
    useEffect(()=>{
        window.scrollTo(0,0);
    }, []);
  return (
    <>
    <div  id="mt_portfolio" className="gallery-section gallery-page portfolio_col_2">
    <h1 className='text-center fontt' style={{marginTop:'15px'}}>Our Services</h1>
          <div className="container">
              <div className="row isotopeContainer">
                  {services.map((service,index) => {
                    return (
                      //
                    <div key={service.id} className={`col-md-4 mb-3 d-flex portfolio_grid no-padding isotopeSelector ${index === services.length - 1 ? 'centered' : ''}`} style={index === services.length - 1 ? { margin: 'auto', display: 'block' } : {}}>
                     <figure className="portfolio_item">
                        <div className='services-card py-5'>
                            <div className='text-center'>
                            <i className={service.icon}></i>
                                <h6 className='mt-3'>{service.title}</h6>
                                <p className='gothic' style={{display:'flex',alignItems:'start'}}>
                                    <span className='small-box' style={{marginRight:'8px',marginTop:'6px'}}> </span> 
                                    <span className='text' style={{flex:'1'}}>
                                    {service.mini}
                                    </span>
                                 
                                </p>
                                <Link to={`/service/${service.link}`} state={{ service }} className='btn btn-violet gothic py-2 px-3 fs-6'>Read More</Link>
                            </div>
                        </div>
                    
                </figure>
                    </div>)
                  })}
                  </div>
          </div>
      </div>
      <div className='container py-5'>
            <div className='my-5'>
                <div className="card bg-gray mb-5">
                    <div className="card-body">
                    <h1 className='text-center fontt'>Translation supported files</h1>
            <p className='text-size gothic' style={{display:'flex',alignItems:'start'}}>
                <span className='box' style={{marginRight:'8px',marginTop:'6px'}}></span> 
                <span className='text' style={{flex:'1'}}>

               
                At Falcon Service, we can handle all types of supported file formats. Once the translation is completed, a team of technical experts will make sure to deliver the translated materials in the same file format.
                </span>
            </p>
            <ul className='list-group list-group-flush'>
                <li className='list-group-item gothic text-size'>
                    <i className="fa-regular fa-file-excel"></i> Microsoft Office files (DOCX, XLSX, PPTX)
                </li>
                <li className='list-group-item gothic text-size'>
                    <i className="fa-regular fa-file-excel"></i> Open Office files (ODT, ODS, ODP)
                </li>
                <li className='list-group-item gothic text-size'>
                    <i className="fa-regular fa-file-excel"></i> Adobe files (INDD, IDML, AI, PDF, JPG, PNG)
                </li>
                <li className='list-group-item gothic text-size'>
                    <i className="fa-regular fa-file-excel"></i> Text files (TXT, CSV, RTF)
                </li>
            </ul>
                    </div>
                </div>
            </div>
            </div>
     
           

       
      <Footer service={test2}/>
    </>
  )
}
