import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const ReviewCard = ({ review }) => {
  return (
    <Card style={{ margin: '1rem' }}>
      <CardContent>
        <Typography variant="h5" component="div">
          {review.review}
        </Typography>
        <Typography>
        <div>
          {[...Array(review.rate)].map((_, i) => (
            <StarIcon className='text-primary' key={i} />
          ))}
        </div>
        </Typography>
        {/* <Typography variant="body2" color="text.secondary">
          - {review.author}
        </Typography> */}
      </CardContent>
    </Card>
  );
};

export default ReviewCard;
