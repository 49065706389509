import React, { useEffect } from 'react'
import Quote from '../components/Quote/Quote'

export default function Contact() {
  useEffect(()=>{
    window.scrollTo(0,0);
  }, []);
  return (
    <div>
      <Quote />
    </div>
  )
}
