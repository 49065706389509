import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';

export default function Quote() {
 const navigate = useNavigate();

  const [errors, setErrors] = useState([]);
  const errorsRef = useRef(null);
  const formRef = useRef(null);
  const initialFormData = {
    client_name: '',
    client_email: '',
    client_phone: '',
    notes: '',
    source_language: '',
    target_language: '',
    files: [],
  };
  
  const [formData, setFormData] = useState(initialFormData);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = new FormData();
    form.append('client_name', formData.client_name);
    form.append('client_email', formData.client_email);
    form.append('client_phone', formData.client_phone);
    form.append('notes', formData.notes);
    form.append('source_language', formData.source_language);
    form.append('target_language', formData.target_language);
    if(formData.files) {
      formData.files.forEach((file, index) => {
        form.append('files', file);
      });
    }
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
    };
  
    try {
      console.log("hi");
      await axios.post('https://falcon-backend.hemmx.org/api/projects/user', form, config);
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Your quote has been sent we will contact you soon!',
      }).then(val=>{
        setErrors([]);
        setFormData(initialFormData);
        formRef.current.reset();
        //alert('data submitted successfuly');
        //navigate('/');
        //console.log('data submitted successuly');
        
        //window.location.reload(); 
        setIsLoading(false);
        window.location.href = '/';
      });


    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
        errorsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = async (e) => {
    const { name, value, files } = e.target;
    let input = value;
    if (files) {
      input = Array.from(files);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: input,
    }));
  }; 
  const handleCancelClick = () => {
    window.location.href = '/';
  };
  return (
    <div id='quoteform'>
        <div className='container py-5' ref={errorsRef}>
            <h1 className='fontt' style={{textAlign:'center'}}>Fast Translation Quote</h1>
            <p className='text-size gothic' style={{display:'flex',alignItems:'start'}}>
              <span className='box' style={{marginRight:'8px',marginTop:'6px'}}></span> 
              <span className='text' style={{flex:'1'}}>
              Let Falcon Service Translations be your trusted partner for all your translation needs. Contact us today for a free consultation and a quote tailored to your specific requirements. We look forward to helping you and aim to reply to all emails within 15 – 30 minutes during working hours, when possible.
              </span>
              </p>
        </div>
      <form onSubmit={handleSubmit} ref={formRef}>
        <div className='container'>
            {errors.length > 0 && (
              <div className='alert alert-danger' role='alert'>
                <ul className='mb-0'>
                  {errors.map((error, index) => (
                    <li className='gothic' key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group mb-3'>
                <label className='gothic' htmlFor='name'>Name</label>
                <input type='text' className='form-control' name='client_name' value={formData.name} onChange={handleInputChange}/>
              </div>
              <div className='form-group mb-3'>
                <label className='gothic' htmlFor='email'>Email</label>
                <input type='email' className='form-control' name='client_email' value={formData.email} onChange={handleInputChange}/>
              </div>
              <div className='form-group mb-3'>
                <label className='gothic' htmlFor='phone'>Phone</label>
                <input type='text' className='form-control' name='client_phone' value={formData.phone} onChange={handleInputChange}/>
              </div>
              <div className='form-group mb-3'>
                <label className='gothic' htmlFor='phone'>Source Language</label>
                <input type='text' className='form-control' name='source_language' value={formData.source_language} onChange={handleInputChange}/>
              </div>
              <div className='form-group mb-3'>
                <label className='gothic' htmlFor='phone'>Target Language</label>
                <input type='text' className='form-control' name='target_language' value={formData.target_language} onChange={handleInputChange}/>
              </div>
            </div>
              <div className='form-group mb-3'>
                <label className='gothic' htmlFor='message'>Notes</label>
                <textarea className='form-control' name='notes' rows='5' onChange={handleInputChange}></textarea>
            </div>
            <div className='form-group mb-3'>
                <label className='gothic'>Files</label>
                <input type='file' multiple className='form-control gothic' name='files' onChange={handleInputChange}/>
            </div>
          </div>
        </div>
        <div className='container text-center py-5' style={{display:'flex', justifyContent:'center', gap: '10px' }}>
  <button type='submit' className='btn btn-violet fs-6 py-2 px-3 text-center gothic'>
    {isLoading ? 'Loading...' : 'Submit Quote'}
        {isLoading && <div class="spinner-border" style={{width: '15px', height: '15px'}} role="status">
                <span class="sr-only">Loading... </span>
              </div>
    }
  </button>
  <button type='button' className='btn btn-violet fs-6 py-2 px-3 text-center gothic' onClick={handleCancelClick}>
        Cancel
      </button>
</div>
      </form>
    </div>
  )
}