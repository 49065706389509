import React, { useState, useEffect } from 'react'
import { Link , useNavigate  } from 'react-router-dom'

export default function Footer({service}) {
  // const [showModal, setShowModal] = useState(false);//!
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  //
  // const [formData, setFormData] = useState({
  //   client_name: '',
  //   client_email: '',
  //   client_phone: '',
  //   notes: ''
  // });//!
  // const handleChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value
  //   });
  // };//!
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await fetch('https://falcon-backend.hemmx.org/api/contact', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(formData),
  //     });

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const result = await response.json();
  //     console.log(result);
  //     // Close modal and reset form
  //     setShowModal(false);
  //     setFormData({
  //       client_name: '',
  //       client_email: '',
  //       client_phone: '',
  //       notes: ''
  //     });
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };//!
  //
  const navigate = useNavigate()
  const open = ()=>{
    if(window.location.pathname === "/"){
      service()  
      console.log("/");    
    }else{
      navigate("/")
      service()
    }
  }
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const handleOpenModal = () => {
  //   setShowModal(true);
  // };//!

  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };//!

  // const handleContactUsClick = () => {
  //   // Define the logic for handling contact us click
  //   console.log('Contact Us clicked');
  //   // Example: Triggering modal open
  //   handleOpenModal();
  // };//!

  // ////////
  // const getResponseMessage = () => {
  //   const now = new Date();
  //   const day = now.getDay();
  //   const hour = now.getHours();

  //   if (day >= 1 && day <= 5) { // Monday to Friday
  //     if (hour >= 9 && hour < 17) {
  //       return 'We will respond to you by email in 15 minutes.';
  //     } else {
  //       return 'We will respond to you by email in a while.';
  //     }
  //   } else { // Saturday and Sunday
  //     return 'We will respond to you by email in a while.';
  //   }
  // };//!
  ////////

  return (
    <>
      <footer className="py-5">
      <div className='container col-10'>
        <div className="row">
        {/* <div className="col-md-6 text-left footer_links">
                    {/* <ul className="list-style display-inline-b my-list">
                        <li className='font-good'><Link to="/">Home</Link></li>
                        <li className='font-good'><Link to="/">About Us</Link></li>
                        <li className='font-good'><Link to="/service">Services</Link></li>
                        {/* <li className='font-good'><Link to="/contact">Contact Us</Link></li> 
                    </ul>
                    
          </div> */}
          <div className="col-md-6 text-right">
            <form>
              <div className="form-group mb-3">
                <label className='gothic' htmlFor="email">Subscribe to our newsletter</label>
                <input type="email" placeholder='Type your email' className="form-control gothic" id="email" />
              </div>
              <button type="submit" className="btn btn-violet fs-6 py-2 px-3 text-center gothic">Subscribe</button>
            </form>
            </div>
          
            <ul className="footer-social" style={{display:'flex',justifyContent:'center',marginTop:'50px'}}>
                    <li>
                            <a href="#" target="_blank" rel="noreferrer"><i className="fs-5 me-2 fa-brands fa-x-twitter"></i></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" rel="noreferrer"><i className="fs-5 me-2 fa-brands fa-facebook"></i></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" rel="noreferrer"><i className="fs-5 me-2 fa-brands fa-linkedin"></i></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" rel="noreferrer"><i className="fs-5 me-2 fa-brands fa-instagram"></i></a>
                        </li>
                        <li>
                            <a href="#" target="_blank" rel="noreferrer"><i className="fs-5 me-2 fa-brands fa-youtube"></i></a>
                        </li>
                    </ul>
         
        </div>
      </div>
      <div style={{ float: 'left' }} className="call-buton">
      {isMobile ? (
        <button className="cc-whatsto-action-ripple" onClick={open} style={{borderRadius: '50%',width: '80px',height: '70px',padding: '6px',paddingTop: '12px',cursor: 'pointer',border:'none'}}>
        <span style={{textAlign:'center'}}>Quote Now</span>
      </button>
      ) : (
        <button 
          className="cc-whatsto-action-ripple" 
          onClick={open} 
          style={{
            borderRadius: '50%',
            width: '80px',
            height: '70px',
            padding: '6px',
            paddingTop: '12px',
            marginRight: '100px',
            marginBottom: '100px',
            cursor: 'pointer',
            border: 'none',
            textJustify:'center'
          }}
        >
          <span className='gothic' style={{textAlign:'center'}}>Quote Now</span>
        </button>
      )}
        </div>
      </footer>
      {/* {showModal && (
        <div className="modal modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-dialog">
    <div class="modal-content">
      {/* <div className='container col-12' style={{display:'flex', justifyContent:'start'}}>
        <div className='row'>
        <button style={{backgroundColor:'#D3D3D3',borderRadius:'15px',width:'120px',height:'35px',padding:'2px',margin:'2px'}}>
      <h6>
      <button type="button" class="btn-close" data-bs-dismiss="modal" onClick={handleCloseModal}></button>
      close
      </h6>
      </button>
        </div>
      </div> }
      <div class="modal-header"style={{backgroundColor:'white'}}>
        <div className='container services-card py-5 pt-1 mt-4 pb-3 align-items-center justify-content-center text-center' style={{width:"280px"}}>
        {/* <h4 style={{color:'#4a259b', fontSize:'16px',textAlign:'center',paddingTop:'8px'}}>Contact Us</h4> }
        <i class="fa-solid fa-comments icon" style={{fontSize:"3rem"}}></i>
        <h4 style={{color:'#4a259b', fontSize:'17px',textAlign:'center', paddingTop:'5px'}}>How can we help?</h4>
        <p style={{fontSize:'0.8rem',textAlign:'center'}}> {getResponseMessage()}</p>
        </div>
      </div>
      <div class="modal-body">
      <form onSubmit={handleSubmit}>
          <div class="form-group">
            <label>Name:</label>
            <input type="text" class="form-control" name="client_name" value={formData.client_name} onChange={handleChange} required></input>
          </div>
          <div class="form-group">
            <label>Phone number:</label>
            <input type="text" class="form-control" name="client_phone" value={formData.client_phone} onChange={handleChange} required></input>
          </div>
          <div class="form-group">
            <label>Email address:</label>
            <input type="email" class="form-control" name="client_email" value={formData.client_email} onChange={handleChange} required></input>
          </div>
          <div class="form-group">
            <label>Write your message here:</label>
            <input type="text" class="form-control" style={{height:'100px'}} name="notes" value={formData.notes} onChange={handleChange} required></input>
          </div>
          <div class="modal-footer" style={{display:'flex',justifyContent:'start'}}>
        <button type="button" class="btn" data-bs-dismiss="modal" onClick={handleCloseModal} style={{backgroundColor:'#4a259b',color:'white'}}>Close</button>
        <button type="submit" class="btn" data-bs-dismiss="modal" style={{backgroundColor:'#4a259b',color:'white'}}>Send a message</button>
      </div>
        </form>
      </div>

      

    </div>
  </div>
        </div>
      )} */}
    </>
  )
}
