import React, { useEffect } from 'react'
import Footer from '../components/Footer/Footer';


export default function OurStory({test}) {
  useEffect(()=>{
    window.scrollTo(0,0);
  }, []);
  return (
    <>
      <br></br>
         <section id="rt_about_content">
            <div className="container col-10">
                <div className="row mb-5">
                    <div className="col-sm-12">
                    <h1 className='text-center fontt'>Our Story</h1>
                    <br></br>
                       <p className='gothic text-size' style={{display:'flex',alignItems:'start'}}>
                       In the realm of global discourse, Falcon Translation Company stands as a beacon of linguistic mastery, reflecting the noble traits of the falcon—Accuracy.Speed. Efficiency.
                       With the precision of a falcon's strike, we deliver translations that resonate with clarity and intent. Our rapid service is the embodiment of the falcon's swift hunt, ensuring messages are conveyed with prompt elegance. The efficiency of our approach mirrors the falcon's seamless soar, offering solutions that embody the fluid grace of this regal bird.
                       As custodians of your narrative, we commit to confidentiality and professionalism as unwavering as the falcon's gaze. Across the UK and the world, we are the silent wings lifting your words—Falcon Translation Company, where communication ascends with Accuracy. Speed. Efficiency.
                       Falcon Translation is your ally in bridging linguistic divides, transcending mere document translation. Our holistic services ensure your message's global resonance.
                       </p>
                       <p className='text-size gothic' style={{display:'flex',textAlign: 'start'}}>
                           <span className='box' style={{marginRight:'8px',marginTop:'6px'}}></span> 
                           <span className='text' style={{flex:'1'}}>
                           Quality Assurance: Our linguists, chosen for their exceptional skill, pledge translations and interpretations that preserve your message's integrity.
                           </span>
                          
                       </p>
                       <p className='text-size gothic' style={{display:'flex',textAlign: 'start'}}>
                           <span className='box' style={{marginRight:'8px',marginTop:'6px'}}></span> 
                           <span className='text' style={{flex:'1'}}>
                           CIOL Accreditation: Our CIOL endorsement signifies our steadfast commitment to linguistic excellence.
                            </span>
                           
                       </p>
                       <p className='text-size gothic' style={{display:'flex',textAlign: 'start'}}>
                           <span className='box' style={{marginRight:'8px',marginTop:'6px'}}></span> 
                           <span className='text' style={{flex:'1'}}>
                           Timeliness: Your time is valued, with a promise of punctual delivery that never sacrifices quality.
                            </span>
                           
                       </p>
                       <br></br>
                       <h1 className='fontt'>Expertise Areas</h1>
                       <br></br>
                       <p className='text-size gothic' style={{display:'flex',textAlign: 'start'}}>
                           <span className='box'  style={{marginRight:'8px',marginTop:'6px'}}></span> 
                           <span className='text' style={{flex:'1'}}>
                           Legal & Immigration: Trust in our meticulous translations for intricate legal terminology.
                       </span>
                       </p>
                       <p className='text-size gothic' style={{display:'flex',textAlign: 'start'}}>
                       <span className='text' style={{flex:'1'}}>
                           <span className='box'  style={{marginRight:'8px',marginTop:'6px'}}></span> 
                           Healthcare: Depend on our prompt, precise translations for essential healthcare communication.
                           </span>
                       </p>
                       <p className='text-size gothic' style={{display:'flex',textAlign: 'start'}}>
                           <span className='box'  style={{marginRight:'8px',marginTop:'6px'}}></span> 
                           <span className='text' style={{flex:'1'}}>
                           Education: Expect timely, accurate translations of educational materials.
                           </span>
                       </p>
                       <br></br>
                       <h1 className='fontt'>Support Services</h1>
                       <br></br>
                       <p className='text-size gothic' style={{display:'flex',textAlign: 'start'}}>
                           <span className='box'  style={{marginRight:'8px',marginTop:'6px'}}></span> 
                           <span className='text' style={{flex:'1'}}>
                           Proofreading: Elevate your documents with our comprehensive proofreading.
                           </span>
                       </p>
                       <p className='text-size gothic' style={{display:'flex',textAlign: 'start'}}>
                           <span className='box'  style={{marginRight:'8px',marginTop:'6px'}}></span> 
                           <span className='text' style={{flex:'1'}}>
                           Certification: Obtain certified translations of personal documents swiftly.
                           </span>
                       </p>
                       <p className='text-size gothic' style={{display:'flex',textAlign: 'start'}}>
                           <span className='box'  style={{marginRight:'8px',marginTop:'6px'}}></span> 
                           <span className='text' style={{flex:'1'}}>
                           Financial: Secure swift, dependable translations of critical financial records.
                           </span>
                       </p>
                       <br></br>
                       <h1 className='fontt'>Specializations</h1>
                       <br></br>
                       <p className='text-size gothic' style={{display:'flex',textAlign: 'start'}}>
                           <span className='box'  style={{marginRight:'8px',marginTop:'6px'}}></span> 
                           <span className='text' style={{flex:'1'}}>
                           Propel your career with translations that mirror your professional achievements.
                           </span>
                       </p>
                       <br></br>
                       <h1 className='fontt'>Interpretation</h1>
                       <br></br>
                       <p className='text-size gothic' style={{display:'flex',textAlign: 'start'}}>
                           <span className='box' style={{marginRight:'8px',marginTop:'6px'}}></span> 
                           <span className='text' style={{flex:'1'}}>
                           Our interpreters, adept in linguistic subtleties and cultural contexts, facilitate clear communication. They are qualified with the most important qualifications and translation certificates accredited in the United Kingdom.
                           </span>
                       </p>
                       <br></br>
                       <br></br>
                       <br></br>
                       <div style={{display:"flex",justifyContent:"center"}}>
                       <h3 className='text-size gothic' style={{ textAlign: 'center',fontWeight:"bold" }}>
                       Choose Falcon Translation for reliable, meticulous, and effective translation and interpretation services, enhancing global connectivity.
                       Falcon Translation: Accuracy. Speed. Efficiency.
                    </h3>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <br></br>
        <Footer service={test} />

    </>
  )
}
