import React from 'react'
import { useLocation } from 'react-router-dom';
import Footer from '../Footer/Footer';
//
export default function ServiceComponent({data,test}) {
    const location = useLocation();
    const service = location.state?.service;
  return (
    <>
    
    <section id="rt_about_content">
            <div className="container col-10">
                <div className="row mb-5">
                    <div className="col-sm-12">
                        <br></br>
                        <br></br>
                        <h1 className='fontt' style={{textAlign:"center"}}>{service.title}</h1>
                        <br></br>
                        <div className='row'>
                    <div>
                        <p className='text-size gothic' style={{display:'flex',alignItems:'start'}}>
                            <span className='box' style={{marginRight:'8px',marginTop:'6px'}}></span> 
                        <span className='text' style={{flex:'1'}}>
                        {service.description}
                        </span>
                         </p>
                    </div>

                </div>
                    </div>
                </div>
            </div>
        </section>
        <br></br>
        <Footer service={test} /> 
        
  
    </>
  )
}
