import React, { useEffect, useState } from 'react'
import Service from '../components/Service/Service'
import Home from './Home';

export default function Services({test}) {
    console.log(test);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [services, setServices] = useState([
        {
            id: 1,
            title: "Legal & Immigration",
            description: "The Precision of Legal Language At Falcon Translation, we ensure every legal term and clause is translated with unparalleled precision. Our translators, experts in British law, treat each document as a vital entity, preserving its original power and intent.Immigration – A Pathway to New BeginningsWe understand the significance of immigration documents and translate them with the care they deserve, ensuring they resonate with the aspirational spirit of our clients.Beyond Translation – Legal Transformation Our translations transform legal contracts into enforceable agreements across borders, maintaining the intent and authority of the original documents.Conclusion Falcon Translation is your trusted partner in navigating the globalized legal landscape, providing translations that carry the authority and precision of British legal expertise.",
            link: "Legal-Immigration",
            icon: "fa-solid fa-scale-balanced icon",
            mini:"The Precision of Legal Language At Falcon Translation, we ensure every legal term and clause is translated with unparalleled precision.",
        },
        {
            id: 2,
            title: "NHS & Healthcare",
            description: "We play a critical role in the NHS by delivering translations that combine medical accuracy with empathetic communication.Precision and Empathy: The Falcon Touch Our team ensures that medical documents, from PILs to clinical reports, are translated with the highest care, making medical information accessible to all.A Bridge Between Cultures We eliminate language barriers in healthcare, providing translations that reflect the UK’s diverse population.Case Studies: Examples of Excellence Our projects, from PILs to clinical studies, showcase our commitment to excellence and cultural sensitivity in translations.The Falcon Promise We pledge to uphold the highest standards of quality and confidentiality, ensuring our translations meet all regulatory standards",
            link: "NHS-Healthcare",
            icon: "fa-solid fa-staff-snake icon",
            mini:"We play a critical role in the NHS by delivering translations that combine medical accuracy with empathetic communication.",
        },
        {
            id: 3,
            title: "Education",
            description: "rvices Falcon Translation propels educational ventures forward with expert academic translations. We ensure every research paper, study, and lecture transcends language barriers, connecting British academia to a worldwide audience. Our linguists excel in translating complex academic works like theses and dissertations, adapting course materials for global programs, and preparing conference materials that foster international scholarly exchange.Adhering to British Law and Translation Rules Our translations reflect the precision and integrity required by British law, with every document meeting stringent legal and academic standards.Competitive Edge in the Market Falcon Translation distinguishes itself with a commitment to transforming academic content into globally engaging material, providing our clients with a competitive advantage.Conclusion : We make education’s passport to the future linguistically valid, culturally sensitive, and globally recognized, expanding knowledge and opportunities without borders.",
            link: "Education",
            icon:"fa-solid fa-book-open-reader icon",
            mini:"Services Falcon Translation propels educational ventures forward with expert academic translations. We ensure every research paper.",
        },
        {
            id: 4,
            title: "Proofreading and Editorial Services",
            description: "Our Proofreading and Editorial Services enhance your translated documents’ precision and clarity. Our proofreaders, experts in British English, ensure grammatical excellence, while our editors craft narratives with compelling style and persuasive tone.Final Arbiters of Readiness Our editorial team perfects your documents, ensuring they’re ready to captivate and inform a global audience.Polished Perfection Awaits We tailor our services to your needs, ensuring compliance with British translation and proofreading standards, and elevating your communication to unmatched excellence",
            link: "Proofreading-Editorial-Services",
            icon:"fa-solid fa-envelope-circle-check icon",
            mini:"Our Proofreading and Editorial Services enhance your translated documents’ precision and clarity. Our proofreaders, experts in British English.",
        },
        {
            id: 5,
            title: "official Identifications",
            description: "Identity Translation Services Falcon Translation specializes in translating key identity documents with precision and professionalism. We ensure passports, driver’s licenses, and birth certificates meet international standards and are meticulously crafted for global recognition and legal compliance.Our Commitment to Quality and Compliance We guarantee accuracy, adherence to British law, and confidentiality, setting us apart with our expertise, cutting-edge technology, and exceptional customer service.Competitive Edge Our translators’ legal expertise and personalized service provide a competitive advantage, ensuring your identity documents are a bridge to your future.",
            link: "official-Identifications",
            icon:"fa-solid fa-fingerprint icon",
            mini:"Identity Translation Services Falcon Translation specializes in translating key identity documents with precision and professionalism. We ensure passports, driver's licenses.",
        },
        {
            id: 6,
            title: "Finance",
            description: "Financial Translation Services Falcon Translation is your partner in the global finance sector, offering precise and accurate financial document translations.Why Choose Falcon Translation? We provide expert translations with comprehensive expertise, regulatory compliance, cultural insight, and a competitive edge.Our Services Include: We translate annual reports, earnings statements, market analyses, and investor communications, tailoring solutions to each client’s needs.Ensuring Your Numbers Narrate Your Story Our commitment to excellence turns your financial data into compelling narratives that resonate globally.",
            link: "Finance",
            icon:"fa-solid fa-chart-line icon",
            mini:"Financial Translation Services Falcon Translation is your partner in the global finance sector, offering precise and accurate financial document translations.",
        },
        {
            id: 7,
            title: "Professional Specializations",
            description: "Specialized Falcon Translation offers specialized services across various sectors, ensuring precision and cultural relevance in every project.Medical Translations: We deliver error-free translations in medical documents, adhering to UK’s MHRA standards, making complex information accessible to all.Scientific Translations: Our expertise in scientific terminology enables us to translate research papers and articles, preserving the original content’s integrity for a global audience.Interior Design Translations: We translate design concepts with an understanding of cultural nuances, ensuring the designer’s vision is communicated with elegance.Media Translations: We provide translations for the media sector, considering local cultural sensitivities and compliance with Ofcom broadcasting codes for the UK audience.Our Pledge: At Falcon Translation, we’re committed to delivering translations that resonate with technical accuracy and creative expression, tailored to your audience’s cultural context. Trust us to elevate your global communications.",
            link: "Professional-Specializations",
            icon:"fa-solid fa-globe icon",
            mini:"Specialized Falcon Translation offers specialized services across various sectors, ensuring precision and cultural relevance in every project.",
        },
    ]);
  return (
    <div>
      <Service services={services} test2={test} />
    
    </div>
  )
}
